/*
 * SCSS Entrypoint
 * - Override Bootstrap variables
**/

// Bootstrap: https://getbootstrap.com/docs/4.2/getting-started/theming/#theme-colors
// React Bootstrap: https://react-bootstrap.github.io/getting-started/introduction/#customize-bootstrap
// These (and other variables) can all be overridden:;
$theme-colors: (
  "primary": rgba(20, 40, 60, 1),
  "secondary": rgba(80, 100, 10, 1),
  "yellow": rgba(255, 213, 0, 1),
  "dark-shade": rgba(40, 41, 42, 1),
  // Primary
  // Secondary
  // Success
  // Danger
  // Warning
  // Info
  // Light
  // Dark,,,,,,,,,,,,,,,,,,,,,,,,
);

@import "~bootstrap/scss/bootstrap.scss";
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700;800&display=swap');
@font-face {
  font-family: "Norwester";
  font-style: normal;
  font-weight: 400;
  src: url("../src/assets/fonts/norwester-v1.2/webfonts/norwester.eot"); /* IE9 Compat Modes */
  src: local("Norwester"), local("Norwester"),
    url("../src/assets/fonts/norwester-v1.2/webfonts/norwester.eot")
      format("embedded-opentype"),
    // /* IE6-IE8 */ url("../src/assets/fonts/norwester-v1.2/webfonts/norwester.woff2")
    // format("woff"),
    url("../src/assets/fonts/norwester-v1.2/webfonts/norwester.woff")
      format("woff"),
    /* Modern Browsers */
      url("../src/assets/fonts/norwester-v1.2/webfonts/norwester.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../src/assets/fonts/norwester-v1.2/webfonts/norwester.svg")
      format("svg"); /* Legacy iOS */
}

body {
  margin: 0;
  margin-top: 46px;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .jumbotron {
    margin-bottom: 0px;
  }
}

h1,
h2,
h3,
h4,
button {
  font-family: "Norwester", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 3px;
}

p {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}

.legacy-logo {
  border-radius: 100%;
}

.hero-elephant {
  opacity: 0.8;
  width: 50%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .hero-elephant {
    width: 20%;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
